<template>
  <div class="flex flex-col items-center space-y-2">
    <div class="w-full">
      <a-button @click="$router.back()">
        Regresar
      </a-button>

      <div class="flex flex-col">

        <course-section-accessor v-model="course_section" />

        <div class="w-full flex flex-wrap -mx-2">

          <template v-if="Object.keys(course_section).length > 0">
            <!-- <div class="flex"> -->
            <div class="w-full px-2">
              <form
                class="space-y-4"
                @submit.prevent="store(mappedDelivery)"
                @keydown="removeError($event.target.id)">
                <h3 class="my-4 text-2xl">
                  Crear entrega final
                </h3>
                <div class="flex flex-wrap">
                    <div class="w-full md:w-1/2 lg:w-1/2 space-y-4">
                      <div class="flex flex-wrap w-full">
                        <a-select
                          label="Aula"
                          required
                          class="flex-grow"
                          id="classroom_id"
                          :loading="loadingClassrooms"
                          :source="classrooms.map(it => ({ label: it.code, value: it.id }))"
                          :error="error('classroom_id', { attribute: 'aula' })"
                          v-model="delivery.classroom_id" />
                      </div>

                      <div class="flex flex-wrap w-full">
                        <a-select
                          label="Jurados"
                          multiple
                          class="flex-grow"
                          id="juries"
                          :loading="loadingTeachers"
                          :source="mappedTeachers.map(it => ({ label: `${it.first_name} ${it.last_name}`, value: it.id }))"
                          :error="error('juries', { attribute: 'profesor' })"
                          v-model.lazy="juries" />
                      </div>

                      <div class="form-group flex" v-if="course_section && course_section.teacher">
                        <a-switch v-model="delivery.teacher_as_jury">
                          <template #suffix>
                            ¿El profesor {{ `${course_section.teacher.first_name} ${course_section.teacher.last_name}` }} puede registrar nota?
                          </template>
                        </a-switch>
                      </div>

                      <a-input
                        label="Resumen (opcional)"
                        id="summary"
                        v-model="delivery.summary" />
                    </div>
                </div>

                <div class="flex flex-wrap space-x-2 mt-4">
                  <a-button mode="primary" type="submit" :loading="loadingClassrooms || loadingTeachers" :disabled="loadingClassrooms || loadingTeachers">
                    Guardar cambios
                  </a-button>

                  <a-button outlined :to="{ name: 'final_deliveries' }">
                    Cancelar
                  </a-button>
                </div>
              </form>
            </div>
            <!-- </div> -->
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CourseSectionAccessor from '@/components/ui/course-section-accessor.vue';
export default {
    components: {
        CourseSectionAccessor
    },
    data: () => ({
        descriptionText: '',
        periods: [],
        specializations: [],
        semesters: [],
        sections: [],
        courses: [],
        ids: {
            period_id: null,
            specialization_id: null,
            semester_id: null,
            section_id: null,
            course_id: null
        },
        semester: {},
        course_section: {},
        delivery: {
            course_section_id: null,
            teacher_as_jury: false,
        },
        juries: [],
        loadingPeriods: false
    }),
    mounted() {
        this.descriptionText = 'Obteniendo períodos...'
        this.loadingPeriods = !this.loadingPeriods
        this.$repository.periods
          .index({ limit: 0, with: 'pensums.specialization,pensums.semesters.specialization' })
          .then(res => this.periods = res.data)
          .finally(() => {
              this.loadingPeriods = !this.loadingPeriods
              this.descriptionText = ''
          })

    },
    computed: {
        courseSectionComposition() {
            return `${this.ids.course_id}|${this.ids.section_id}`
        },
        mappedTeachers() {
            return this.teachers.filter(it => it.id !== this.course_section?.teacher_id)
        },
        mappedDelivery() {
            return {
                ...this.delivery,
                course_section_id: this.course_section.id,
                juries: this.juries.map(it => ({teacher_id: it}))
            }
        },
        ...mapState({
            classrooms: state => state.classrooms.all,
            loadingClassrooms: state => state.classrooms.loading,
            user: state => state.session.user,
            teachers: state => state.teachers.all,
            loadingTeachers: state => state.teachers.loading
        }),
         ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
    },
    methods: {
        ...mapActions({
            store: 'finalDeliveries/store',
            removeError: 'removeError',
            fetchClassrooms: 'classrooms/index',
            fetchTeachers: 'teachers/index'
        })
    },
    watch: {
        'ids.period_id'(val) {
            if (val) {
                let specializations = []
                const found = this.periods.find(it => it.id === val)
                if (found) {
                    found.pensums.forEach(it => !specializations.some(el => el.id === it.specialization_id) && specializations.push(it.specialization))
                    this.specializations = specializations
                }
            }
        },
        'ids.specialization_id'(val) {
            if (val) {
                let semesters = []
                const found = this.periods.find(it => it.pensums.find(el => el.specialization_id == val))

                if (found) {
                    found.pensums.forEach($0 => {
                        $0.semesters.forEach($1 => {
                            if (!semesters.some($2 => $2.id === $1.id)) {
                                if ($1.specialization_id === val) {
                                    semesters.push($1)
                                }
                            }
                        })
                    })

                    this.semesters = semesters
                }
            }
        },
        'ids.semester_id'(val) {
            if (val) {
                this.descriptionText = 'Obteniendo materias y secciones...'
                this.$repository.semesters
                  .show(val)
                  .then(res => this.semester = res.data)
                  .finally(() => this.descriptionText = '')
            }
        },
        course_section(val) {
            if (val) {
                this.fetchTeachers({
                    enabled: true,
                    limit: 0
                })

                this.fetchClassrooms({ limit: 0, enabled: true })
            }
        }
    }
}
</script>
